.page-item.active .page-link {
    z-index: inherit;
}

a.paginator-active-page, a.paginator-active-page:focus {
    color: white !important;
    border: 1px solid #dee2e6 !important;
}

.text-info {
    color: var(--dark) !important;
}

.text-info-active {
    color: white !important;
}

.bg-info {
    background-color: var(--dark) !important;
}

a.bg-info:focus, a.bg-info:hover, button.bg-info:focus, button.bg-info:hover {
    background-color: var(--dark) !important;
}

a.text-info:focus, a.text-info:hover {
    color: var(--dark) !important;
}

a.text-info-active:focus, a.text-info-active:hover {
    color: white !important;
}

.table td, .table th, .table thead th {
    border: 0;
}

.tab-content {
    padding: 1em;
    background: rgb(245, 245, 245);
    background: linear-gradient(180deg, rgba(245, 245, 245, 1) 50%, rgba(250, 250, 250, 1) 100%);
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    background-color: whitesmoke;
    border-color: var(--primary) var(--primary) whitesmoke;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: whitesmoke;
    font-weight: bold;
    border-color: var(--primary) var(--primary) whitesmoke;
}

a.nav-link:hover {
    color: var(--dark) !important;
}

.nav-tabs {
    border-bottom: 1px solid var(--primary);
}

mark {
    background-color: tomato !important;
    color: white;
    border: 1px solid red;
    padding: 0 0.1em;
}

.btn-outline-info, .btn-outline-info:disabled {
    color: var(--primary);
    border-color: var(--primary);
}

.btn-outline-info:hover, .btn-outline-info:active {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-outline-info:focus {
    box-shadow: 0 0 0 0.2rem var(--secondary) !important;
    color: var(--primary) !important;
    border-color: var(--primary) !important;
    background-color: white !important;
}

.btn-outline-info.disabled {
    color: var(--primary);
}

.btn-outline-info.disabled:hover {
    color: white;
}

.badge-info {
    background-color: var(--primary);
}

.dropdown-menu {
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
}

.table-striped tbody tr:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, .05);
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .02);
}

.modal-content {
    -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
    border: 1px solid #ffffff;
}

.modal-header {
    background-color: floralWhite;
}

.modal-backdrop {
    display: none;
}

.modal {
    background: rgba(0, 0, 0, 0.5);
}

.custom-control {
    text-align: center;
    z-index: inherit !important;
}

.custom-control label:hover {
    cursor: pointer;
}

.popoverHelper {
    cursor: pointer;
}