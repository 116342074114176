table .product-sous-famille{
    font-weight: bold;
    font-size: .9em;
}

table .product-caracteristiques{
    font-size: .8em;
    text-align: justify;
}

table .product-ref{
    font-weight: bold;
}

table .va-middle{
    vertical-align: middle;
    text-align: center;
}

table .product-quantity-input{
    max-width: 60px;
}

table .product-button{
    max-width: 150px;
    font-size: .9em;
    margin: 0 auto;
}

.product-img {
    max-height: 120px;
    width: auto !important;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.zoom-in {
    cursor: zoom-in;
}

table select{
    max-width: 200px;
}