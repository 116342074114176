.famille-title{
    min-height: 50px;
}

.famille-title h4{
    font-size: .9em;
    line-height: 1.3em;
}

.bg-round-grey{
    background-color: rgb(235, 235, 235);
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
}
.blocFamilies{
    height: 150px;
    width: 150px;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 1px;
    padding-right: 1px;
}