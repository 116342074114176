/** ===============================================
    ================== NAVIGATION =================
    =============================================== */

.navbar-nav {
    width: 100%;
}

.navbar-nav a, .navbar-nav a:hover {
    color: var(--menu-primary-color);
}

.navbar-nav a {
    border: 1px solid transparent;
    border-radius: 3px;
    text-decoration: none;
}

.navbar-nav a:hover:not(.logo-item):not(.dropdown-item):not(.cart-item):not(.selected) {
    border: 1px solid var(--menu-border-color-hover);
    border-radius: 3px;
    background-color: var(--menu-background-color);
    text-decoration: none;
}

.navbar-nav a:not(.logo-item).selected {
    border: 1px solid var(--menu-border-color-selected);
    border-radius: 3px;
    background-color: var(--menu-background-color);
    text-decoration: none;
    cursor: default;
}

.dropdown-item i {
    margin-right: 0.5em;
}

a.nav-link {
    color: var(--dark) !important;
}

a.nav-link:hover {
    color: var(--primary) !important;
}

a.nav-item {
    color: var(--menu-secondary-color);
}